:root {
  --border-gray: #666664;
  --light-border-gray: #dadce0;
  --body-text-gray: #2e2e2e;
  --heading-text-gray: #111111;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--body-text-gray);
  /* font-family: "Montserrat"; */
  /* font-family: "Lato", sans-serif; */
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  margin: 0;
  /* overflow: visible; */
}

a {
  text-decoration: none;
}

/* div {
  overflow: visible;
} */
.contact-links {
  color: var(--border-gray);
  text-decoration: none;
}

.contact-links:hover {
  color: var(--light-border-gray);
  text-decoration: underline;
}

h1 {
  color: var(--heading-text-gray);
}

.App {
  margin: 0px;
  font-family: sans-serif;
  text-align: center;
}

.big-text-area {
  width: 100%;
  height: 8rem;
}

.section-div {
  scroll-margin-top: 5rem;
}

.header-section {
  height: calc(100vh - 6rem);
  min-height: 16rem;
  padding: 1rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 100%; */
  background-position: 30% 50%;
  /* background-image: url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg"); */
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 1) 100%),
    url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg");
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 1) 100%),
    url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg");
  background-image: -o-linear-gradient(rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 1) 100%),
    url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg");
  background-image: -ms-linear-gradient(rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 1) 100%),
    url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%),
    url("https://res.cloudinary.com/momentum-media-group-pty-ltd/image/upload/v1686795211/Space%20Connect/space-exploration-sc_fm1ysf.jpg");
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up-special {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header-title {
  color: #e9e9e9;
  position: absolute;
  font-size: min(4rem, max(3rem, min(10vh, 10vw)));
  margin: 0rem;
  text-shadow: 0.1rem 0.1rem black;
  animation: slide-up 1s ease-in-out;
}

.header-title-special {
  color: #e9e9e9;
  position: absolute;
  font-size: min(4rem, max(3rem, min(10vh, 10vw)));
  margin: 0rem;
  text-shadow: 0.1rem 0.1rem black;
  animation: slide-up-special 1s ease-in-out;
}

#header-title-researcher {
  top: 40%;
  left: 2rem;
}

#header-title-programmer {
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
}

#header-title-scientist {
  top: 80%;
  right: 4vh;
}

#header-title-name {
  font-size: min(min(25vh, 12vw), 10rem);
  right: 4vh;
  top: 2vh;
  animation: none;
}

@keyframes arrow-pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.header-arrows {
  position: absolute;
  top: 70%;

  left: 5%;
  color: #e9e9e9;
  position: absolute;
  font-size: 6rem;
  font-weight: 1rem;
  margin: 0rem;
  text-shadow: 0.1rem 0.1rem black;
  opacity: 0;
  animation: arrow-pulse 4s ease-in-out infinite;

  /* animation: arrow-pulse 1s ease-in-out; */
}

#chev-arrow1 {
  transform: translateY(-2rem);
}

#chev-arrow2 {
  animation-delay: 0.3s;
  transform: translateY(0rem);
}

#chev-arrow3 {
  animation-delay: 0.6s;
  transform: translateY(2rem);
}

.about-me-text {
  margin: 2rem 00% 2rem;
  /* line-height: 2em; */
}

.about-me-text-div {
  margin: 1rem 10% 4rem;
  line-height: 2em;
}

/* #f0f1f1 */
.about-me-intro {
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: black;
  margin: 2rem auto 3rem;
}

.headShotImg {
  height: 12rem;
  width: 12rem;
  border-radius: 100%;
  /* margin-top: 4rem; */
  box-shadow: 0.5rem 0.5rem 0.75rem var(--light-border-gray);
}

.pageDivider {
  width: 80%;
  color: var(--light-border-gray);
}

.nav-logo,
.nav-location {
  animation: fade-in 1s ease-in-out;
}

.nav-location {
  color: var(--border-gray);
  text-decoration: none;
}

.nav-location:hover {
  color: var(--light-border-gray);
  text-decoration: underline;
}

.nav-current-location {
  color: white;
  transition: all 0.5s;
}

.nav-bar-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 4rem;
  font-size: 1.5rem;
  background-color: black;
  color: white;
  margin: 0;
  width: 100%;
  box-shadow: 0 0.35em 0.3em -0.3em gray;
  position: -ms-sticky;
  position: sticky;
  z-index: 5;
  top: 0px;
}

.nav-menu {
  margin-right: 2rem;
  cursor: pointer;
}

.nav-logo {
  font-size: 1.75em;
  margin-left: 2rem;
  font-weight: 600;
  color: inherit;
}

.nav-menu {
  margin-right: 2rem;
}

.carousel-buttons {
  position: absolute;
  color: #f2f2f2;
  z-index: 1;
  font-size: 3rem;
  cursor: pointer;
}

.carousel-buttons:hover {
  position: absolute;
  color: #c8c8c8;
  /* shadow: ; */
}

.center-react-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tag-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.project-tag-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-form {
  margin: 2rem auto;
  width: 70%;

  /* box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray); */
  /* border-radius: 3rem; */
}

.close-span {
  position: absolute;
  top: 0.6rem;
  right: 1.25rem;
  font-size: 2.5rem;
  color: rgb(100, 100, 100);
  cursor: pointer;
}

.blurred-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  backdrop-filter: blur(0.3rem);
}

.popup-box {
  z-index: 11;
  /* Specify a stack order in case you're using a different order for other elements */
  position: fixed;
  background-color: white;
  border-radius: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0.25rem 0.25rem 0.5rem var(--light-border-gray);
  padding: 1.5rem 0.5% 1rem;
  width: 70%;
  max-height: 80%;
  max-width: 40rem;
}

.popup-body {}

/* .popup-box::-webkit-scrollbar {
  width: 5px;
} */
.popup-box-scroll-bar-div {
  overflow-y: scroll;
  max-height: 80vh;
  padding: 0rem 4.5%;
}

.popup-box-no-scroll-bar-div {
  padding: 0rem 4.5%;
}

.popup-box-scroll-bar-div::-webkit-scrollbar {
  width: 0.6rem;
}

.popup-box-scroll-bar-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.7rem grey;
  border-radius: 0.7rem;
}

.popup-box-scroll-bar-div::-webkit-scrollbar-thumb {
  background-color: rgb(150, 150, 150);
  border-radius: 0.7rem;
}

.popup-box-scroll-bar-div::-webkit-scrollbar-thumb:hover {
  background-color: rgb(100, 100, 100);
  border-radius: 0.7rem;
}

/* .popup-box:-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */
.app.paused * {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  /* animation: none !important;
  transition: none !important; */
}

.popup-img {
  height: 16rem;
}

input,
textarea {
  border: 0;
  outline: 0;
  padding: 1rem;
  border-radius: 8px;
  box-sizing: border-box;
  /* width: 100%; */
  margin-top: 1rem;
  font-family: "Merriweather", sans-serif;
  box-shadow: 0.1rem 0.1rem 0.5rem var(--light-border-gray);
  resize: none;
}

.contact-user-info-div {
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem auto 2rem;
  width: 20rem;
  /* display: inline; */
}

.submit-button {
  cursor: pointer;
  border: none;
  height: 3rem;
  width: 10rem;
  font-size: 1.25rem;
  margin: 2rem auto 2rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 3rem;
  /* justify-content: center; */
  /* box-shadow: 0.1rem 0.1rem 0.3rem; */
  box-shadow: 0.1rem 0.1rem 0.3rem var(--light-border-gray);
  transition: all 0.5s;
}

/* .error-input{ */

/* } */
.sent-button {
  cursor: default;
  border: none;
  height: 3rem;
  width: 10rem;
  font-size: 1.25rem;
  margin: 2rem auto 2rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 3rem;
  /* justify-content: center; */
  /* box-shadow: 0.1rem 0.1rem 0.3rem; */
  box-shadow: 0.1rem 0.1rem 0.3rem var(--light-border-gray);
  transition: all 0.5s;
  background-color: rgb(220, 220, 220);
}

.submit-button:hover {
  transform: scale(1.05);
  /* height: 3.5rem;
  line-height: 3.5rem;
  width: 10.5rem;
  margin: 1.75rem auto 1.75rem; */
  background-color: rgb(220, 220, 220);
  /* justify-content: center; */
  /* box-shadow: 0.1rem 0.1rem 0.3rem; */
}

.submit-button1 {
  cursor: pointer;
  border: none;
  height: 3rem;
  width: 10rem;
  font-size: 1.25rem;
  margin: 2rem auto 2rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 3rem;
  box-shadow: 0.1rem 0.1rem 0.3rem var(--light-border-gray);
  transition: all 0.5s;
  background-color: rgb(60, 60, 60);
  color: rgb(220, 220, 220);
}

.submit-button1:hover {
  transform: scale(1.05);
  background-color: rgb(80, 80, 80);
}

.input-error {
  box-shadow: 0.1rem 0.1rem 0.5rem #e07284;
}

.input-error::placeholder {
  color: #e04f67;
}

.contact-info,
.contact-body {
  font-size: 1rem;
  padding: 1.25rem 1rem;
  transition: all 0.6s;
}

.contact-info:focus,
.contact-body:focus {
  font-size: 1.25rem;
  padding: 1.125rem 1rem;
  /* box-shadow: 0.2rem 0.2rem 0.5rem var(--light-border-gray); */
}

.project-card-holder {
  position: relative;
  margin: auto;
  width: 80%;
  transition: all 1s;
}

.project-card {
  /* border: 0.2rem solid var(--border-gray); */
  margin: 0rem;
  box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray);
  /* border-radius: 3rem; */
  overflow: hidden;
  background-color: white;
}

.project-text-clamp {
  -webkit-line-clamp: 10;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.project-heading {
  padding: 0rem 5%;
}

.project-image {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}

.project-body {
  margin: auto 10% 1rem;
}

.skill-card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* display: grid;
grid-tremplate-columns:3 */
.skill-card {
  cursor: pointer;
  background-color: white;
  /* border: 0.2rem solid var(--border-gray); */
  box-shadow: 0.25rem 0.25rem 0.5rem var(--light-border-gray);
  border-radius: 2rem;
  padding: 1rem;
  margin: 1rem;
  position: relative;
  transition: all 0.3s;
  /* animation: fade-in 1s ease-in-out; */
}

.skill-card:hover {
  box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray);
  margin: 0.75rem;
}

.skill-card-big {
  width: 16rem;
  height: 20.5rem;
}

@keyframes div-expand {
  from {
    height: 0rem;
  }

  to {
    height: auto;
  }
}

/* @keyframes div-collapse {
  0% {
    height: auto;
  }
  50% {
    height: 0rem;
  }
  100% {
    height: auto;
  }
} */
.collapes {
  animation: div-collapse 2s both;
}

.expands {
  animation: div-expand 0.5s forwards;
}

.skill-card-big:hover {
  width: 16.5rem;
  height: 21rem;
}

.skill-card-small {
  width: 8.5rem;
  height: 11rem;
}

.skill-card-small:hover {
  width: 9rem;
  height: 11.5rem;
}

.skill-heading {
  margin: 0.4rem auto 0.3rem;
}

.skill-body {
  display: -webkit-box;
  margin: 0.3rem auto 0.5rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.skill-logo {
  height: 8rem;
  /* width: 15rem; */
}

.skill-tag {
  border: 0.1rem solid var(--light-border-gray);
  border-radius: 0.4rem;
  margin: 0.5rem 0.2rem;
  padding: 0.2rem;
}

.skill-button {
  margin: 1rem;
  transition: all 0.5s;
}

.skill-button-active {
  font-size: 1.5rem;
  margin: 0.825rem;
  color: var(--border-gray);
}

.skill-button-inactive {
  color: var(--light-border-gray);
}

.skill-button-inactive:hover {
  font-size: 1.4rem;
  margin: 0.8rem;
  text-decoration: underline;
}

.welcome-img {
  width: 70%;
  margin: 2rem auto;
  /* border-radius: 3rem; */
  object-fit: cover;
  overflow: hidden;
  height: 30vw;
  box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray);
}

.inactive {
  opacity: 0;
  z-index: -2;
  position: absolute;
}

.active {
  z-index: 0;
  transform: translateX(0%) scale(1);
  transition: all 1s;
  position: absolute;
}

.inactive-left {
  z-index: -1;
  transform: translateX(-50%) scale(0.7);
  opacity: 0.25;
  position: absolute;
  transition: all 1s;
}

.inactive-right {
  z-index: -1;
  opacity: 0.25;
  transform: translateX(50%) scale(0.7);
  position: absolute;
  transition: all 1s;
}

@media (max-width: 750px) {
  .nav-location {
    display: none;
  }
}

@media (max-width: 650px) {
  .header-arrows {
    display: none;
  }
}

.sectionTitle {
  padding: 8rem 0 2rem;
  font-size: 4rem;
  line-height: 2rem;
  margin: 0;
  margin-bottom: 2rem;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2em;
  display: block;
  font-weight: 600;
  color: #1d1e22;
}

/* @media screen and (min-width: 768px) .sectionTitle {
  padding-top: 4em;
} */

.drop-down-item {
  border-bottom: #c3c3c3 solid 1px;
  /* padding: 0.3rem; */
  padding: 0.3rem 1rem;
  border-radius: 0.2rem;
  color: #757575;
}

.drop-down-item:hover {
  background-color: rgb(202, 202, 202);
  box-shadow: 0 0.35em 0.3em -0.3em gray;
  color: #383838
    /* border-bottom: #111111 solid 1px; */
    /* padding: 0.3rem; */
}

.drop-down-link {
  color: inherit
}

a {
  cursor: pointer;
}

.drop-down-item-top {
  /* border-top: #111111 solid 1px; */
  /* padding: 0.3rem; */
  padding-top: 0rem;
}

.drop-down {
  font-size: 1.5rem;
  background-color: rgb(230, 229, 229);
  color: var(--body-text-gray);
  margin: 0;
  position: relative;
  font-size: 1.2rem;
  /* width: 20%; */
  padding: 0.25rem 0rem 0.75rem;

  /* height: 8rem; */
  /* display: flex; */
  /* align-items: center; */
  box-shadow: 0 0.35em 0.3em -0.3em gray;
  justify-content: space-between;
  position: absolute;
  z-index: 4;
  top: 4rem;
  right: 0rem;
  transition: all 0.5s;
}

.drop-down-collapsed {
  display: none;
}

.overlay-window {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}

.project-button {
  cursor: pointer;
  border: none;
  display: block;
  background-color: #ededed;

  height: 3;
  width: 10rem;
  font-size: 1.25rem;
  margin: 0.2rem auto 0.2rem;
  border-radius: 1rem;
  text-align: center;
  line-height: 3rem;
  color: #494949;
  /* justify-content: center; */
  /* box-shadow: 0.1rem 0.1rem 0.3rem; */
  box-shadow: 0.1rem 0.1rem 0.3rem var(--light-border-gray);
  transition: all 0.5s;
}

.project-button:hover {
  transform: scale(1.05);
  /* height: 3.5rem;
  line-height: 3.5rem;
  width: 10.5rem;
  margin: 1.75rem auto 1.75rem; */
  background-color: rgb(220, 220, 220);
  /* justify-content: center; */
  /* box-shadow: 0.1rem 0.1rem 0.3rem; */
}

.blog-item {
  background-color: white;
  box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray);
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 1.5rem auto;
  transition: transform 0.3s;
  position: relative;
  width: 80%
}

.blog-item:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0.5rem 0.5rem 1.5rem var(--light-border-gray);
}

.blog-item h2 {
  margin-top: 0;
  font-size: 1.5rem;
  color: var(--heading-text-gray);
}

.blog-item p {
  color: var(--body-text-gray);
  margin: 0.5rem 0;
}

.blog-item a {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 3.0rem;
  /* color: var(--border-gray); */
  text-decoration: none;
}

.blog-item a:hover {
  text-decoration: underline;
  /* color: var(--light-border-gray); */
}

.blog-post {
  background-color: white;
  box-shadow: 0.5rem 0.5rem 1rem var(--light-border-gray);
  border-radius: 1rem;
  padding: 1.5rem;

  width: 70%;
  margin: 2rem auto
}

.h1-blog {
  /* Add your h1 styles here */
  color: var(--heading-text-gray);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.h2-blog {
  /* Add your h2 styles here */
  color: var(--heading-text-gray);
  font-size: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.p-blog {
  /* Add your paragraph styles here */
  color: var(--body-text-gray);
  line-height: 1.6;
  margin: 1rem 2rem 1rem;

}

.img-blog {
  /* Add your image styles here */
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}


.pageDividerBlog {
  width: 50%;
  color: var(--light-border-gray);
  margin: 4rem auto 4rem
}

.blog-return {
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: var(--border-gray);
  text-decoration: none;
}

.blog-return:hover {
  text-decoration: underline;
  color: var(--light-border-gray);
}

.blog-return-rhs {
  position: absolute;
  right: 15%;
  top: 4.5rem;
  color: var(--border-gray);
  text-decoration: none;
  font-size: 1rem;
}

.blog-return-rhs:hover {
  text-decoration: underline;
  color: var(--light-border-gray);
}